import axios from "axios";
const getActiveProfessionalsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/professional-log/get-active`;

export const getActiveProfessionals = async (token) => {
  return await axios
    .get(getActiveProfessionalsUrl, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      
    });
}