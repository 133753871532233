import { Box, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { showToast, ToastType } from "../utils/ToastUtil";
import { getAllReports } from "../services/reportsService";
import { getActiveProfessionals } from "../services/professionalLogService";
import { useToast } from "../context/Toast/ToastProvider";
import { useStateAuthValue } from "../context/AuthState";
import { CircularProgress } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import "../styles/activeprofessionalsListStyle.scss";
const ProfessionalLog = () => {
  const [{ userToken }] = useStateAuthValue();
  const [activeProfessionals, setActiveProfessionals] = useState([]);
  const [logedOutProfessionals, setLogedOutProfessionals] = useState([]);
  const [loading, setLoading] = useState(true);
  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const getActiveProfessionalsList = async () => {
    await getActiveProfessionals(userToken).then(
      ({ activeProfessionals, logedOutProfessionals }) => {
        setLoading(false);
        if (activeProfessionals && logedOutProfessionals) {
          const sortedActiveProfessionals = activeProfessionals.sort(
            (a, b) => new Date(b.last_active) - new Date(a.last_active)
          );

          const sortedLogedOutProfessionals = logedOutProfessionals.sort(
            (a, b) => new Date(b.last_logout) - new Date(a.last_logout)
          );

          setActiveProfessionals(sortedActiveProfessionals);
          setLogedOutProfessionals(sortedLogedOutProfessionals);
        }
      }
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getActiveProfessionalsList();
    }, 5000); // Se ejecuta cada 2 segundos

    return () => clearInterval(interval); // Limpieza al desmontar
  }, [userToken]);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}/${month} ${hours}:${minutes}`;
  };
  const renderProfessionaLogs = (list, isLogOut) => {
    if (!list.length) {
      return (
        <Box
          width={"1rem"}
          height={"2px"}
          borderRadius={"10px"}
          bgcolor={"#002c54"}
          mt={"1rem"}
        ></Box>
      );
    }

    return list?.map(
      ({ event, id, name, last_active, specialities, last_logout }) => {
        let isActive = event != "LogOut" && event != "EndedUnexpectedly";
        let speciality = "";
        switch (specialities) {
          case "b3792b24-85a8-4373-8b06-c0d48ec744a4":
            speciality = "Clinica Medica";
            break;
          case "e09fa8d7-77f3-4c63-93e1-19e6caf1f6fc":
            speciality = "Pediatría";
            break;
          default:
            break;
        }
        return (
          <Box className={`item ${isLogOut && "disabled"}`} key={id}>
            <span>{name}</span>
            <span>{speciality}</span>
            <Box className="last-active">
              <span>
                {isLogOut
                  ? last_logout
                    ? formatDate(last_logout)
                    : formatDate(last_active)
                  : formatDate(last_active)}
              </span>
              {!isLogOut && (
                <Brightness1
                  style={{ color: isActive ? "#00cc00" : "#ff0000" }}
                ></Brightness1>
              )}
            </Box>
          </Box>
        );
      }
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <div
        className="users-table-container"
        style={{ padding: "1rem", position: "relative" }}
      >
        <Box
          sx={{
            background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
            padding: "10px",
            borderRadius: "15px",
            color: "white",
            height: "80px",
            display: "flex",
            alignItems: "center",
            width: "90%",
            position: "relative",
            bottom: "25px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: " 700",
              letterSpacing: " 1px",
            }}
          >
            Profesionales en Linea
          </Typography>
        </Box>
        <Box className="active-professionals-list">
          {loading ? (
            <Box className="loader">
              <CircularProgress></CircularProgress>
            </Box>
          ) : (
            <>
              <Box className="header">
                <h2>Profesional</h2>
                <h2>Especialidad</h2>
                <h2>Ult. Actividad</h2>
              </Box>
              {renderProfessionaLogs(activeProfessionals, false)}
              <Box className="header">
                <h2>Profesional</h2>
                <h2>Especialidad</h2>
                <h2>Ult. Cierre Sesión</h2>
              </Box>
              {renderProfessionaLogs(logedOutProfessionals, true)}
            </>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};
export default ProfessionalLog;
