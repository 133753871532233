import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
  Typography,
  TableBody,
  TableHead,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MuiTheme } from "../styles/MuiDataTableStyle";
import { LoadingModal } from "../components/Modals/LoadingModal";
import {
  deleteChannelsPricing,
  deleteSalesPoints,
  deleteSellerSalesPoints,
  getChannels,
  getChannelsPricing,
  getSalesPoints,
  getSellerBySalesPoints,
  getSellerSalesPoints,
  postChannelsPricing,
  postSalesPoints,
  postSellerSalesPoints,
  putChannelsPricing,
  putSalesPoint,
  putSellerSalesPoints,
} from "../services/channelsService";
import { useStateAuthValue } from "../context/AuthState";
import {
  Add,
  Clear,
  Delete,
  DeleteForever,
  Edit,
  X,
} from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { BaseModal } from "../components/Modals/BaseModal";
import { FormikField } from "../components/FormFields";
import { showToast, ToastType } from "../utils/ToastUtil";
import { useToast } from "../context/Toast/ToastProvider";
const ChannelsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState([
    {
      id: "2a511a20-e69f-47b5-bb6e-32b83792f6ff",
      financer: "Mediline",
      description: "Empresa de telemedicina originaria de Mendoza,Argentina.",
    },
  ]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentSalesPoint, setCurrentSalesPoint] = useState(null);
  const [salesPoints, setSalesPoints] = useState([
    {
      id: "",
      salePointId: "",
      name: "",
      description: "",
      channels: {
        id: "",
        financer: "",
        description: null,
      },
    },
  ]);
  const [sellerSalesPoints, setSellerSalesPoints] = useState([
    {
      id: "",
      mail: "",
      fullName: "",
      dni: "",
      salePointId: "",
      sellerToken: "",
    },
  ]);
  const [{ userToken }] = useStateAuthValue();
  const [currentModalOpen, setCurrentModalOpen] = useState(null);

  const [currentConfirmDialogOpen, setCurrentConfirmDialogOpen] =
    useState(null);
  const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [channelsPricing, setChannelsPricing] = useState([
    {
      id: "",
      channelId: "",
      financerPlanId: "",
      price: 0,
    },
  ]);

  const saleUrl = process.env.REACT_SALE_URL;

  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const displaySucces = (msg) =>
    showToast(toastDispatch, msg, ToastType.SUCCESS, ToastType.SUCCESS);

  const GetChannelsList = async () => {
    setLoading(true);
    await getChannels(userToken).then((res) => {
      setLoading(false);
      setChannels(res);
    });
  };

  const GetSalesPointsList = async (channelId) => {
    setLoading(true);
    await getSalesPoints(userToken, channelId).then((res) => {
      setLoading(false);
      setSalesPoints(res);
    });
  };

  const handleSalesPoint = async (
    action,
    params = null,
    salePointId = null
  ) => {
    setLoading(true);

    let response;
    switch (action) {
      case "POST":
        response = await postSalesPoints(userToken, params, displayError);
        if (response) displaySucces("Punto de venta creado con éxito");
        break;

      case "PUT":
        response = await putSalesPoint(
          userToken,
          salePointId,
          params,
          displayError
        );
        if (response) displaySucces("Punto de venta editado con éxito");
        break;

      case "DELETE":
        response = await deleteSalesPoints(
          userToken,
          salePointId,
          displayError
        );
        if (response) displaySucces("Punto de venta borrado con éxito");
        break;

      default:
        console.error("Acción no válida");
        return;
    }

    if (response) {
      GetSalesPointsList(currentChannel.id);
      GetSellerSalesPointsList(currentChannel.id);
      setCurrentSalesPoint(null);
    }

    if (action !== "POST") setSelectedSalesPoint(null);
    if (action === "DELETE") setCurrentConfirmDialogOpen(null);
    if (action === "POST" || action === "PUT") setCurrentModalOpen(null);

    setLoading(false);
  };

  const GetSellerSalesPointsList = async (channelId) => {
    setLoading(true);
    await getSellerSalesPoints(userToken, channelId).then((res) => {
      setLoading(false);
      setSellerSalesPoints(res);
    });
  };

  const GetSellerBySalesPoints = async (salesPointsId) => {
    setLoading(true);
    await getSellerBySalesPoints(userToken, salesPointsId).then((res) => {
      setLoading(false);
      setSellerSalesPoints(res);
    });
  };

  const handleSeller = async (action, params = null, sellerId = null) => {
    setLoading(true);

    let response;
    switch (action) {
      case "POST":
        response = await postSellerSalesPoints(userToken, params, displayError);
        if (response) displaySucces("Asesor creado con éxito");
        break;

      case "PUT":
        response = await putSellerSalesPoints(
          userToken,
          sellerId,
          params,
          displayError
        );
        if (response) displaySucces("Asesor editado con éxito");
        break;

      case "DELETE":
        response = await deleteSellerSalesPoints(
          userToken,
          sellerId,
          displayError
        );
        if (response) displaySucces("Asesor borrado con éxito");
        break;

      default:
        console.error("Acción no válida");
        return;
    }

    if (response) {
      if (currentSalesPoint) {
        GetSellerBySalesPoints(currentSalesPoint.salePointId);
      } else {
        GetSellerSalesPointsList(currentChannel.id);
      }
    }

    if (action !== "POST") setSelectedSeller(null);
    if (action === "DELETE") setCurrentConfirmDialogOpen(null);
    if (action === "POST" || action === "PUT") setCurrentModalOpen(null);

    setLoading(false);
  };

  const GetChannelsPricing = async (channelId) => {
    setLoading(true);
    await getChannelsPricing(userToken, channelId).then((res) => {
      setLoading(false);
      setChannelsPricing(res);
    });
  };

  const handleChannelsPricing = async (action, params = null) => {
    setLoading(true);

    let response;
    switch (action) {
      case "POST":
        response = await postChannelsPricing(
          userToken,
          currentChannel.id,
          params,
          displayError
        );
        if (response) displaySucces("Precios creados con éxito");
        break;

      case "PUT":
        response = await putChannelsPricing(
          userToken,
          currentChannel.id,
          params,
          displayError
        );
        if (response) displaySucces("Precios editados con éxito");
        break;

      case "DELETE":
        response = await deleteChannelsPricing(
          userToken,
          currentChannel.id,
          displayError
        );
        if (response) displaySucces("Precios borrados con éxito");
        break;

      default:
        console.error("Acción no válida");
        return;
    }

    if (response) {
      GetChannelsPricing(currentChannel.id);
    }

    if (action !== "POST") setSelectedSalesPoint(null);
    if (action === "DELETE") setCurrentConfirmDialogOpen(null);
    if (action === "POST" || action === "PUT") setCurrentModalOpen(null);

    setLoading(false);
  };
  useEffect(() => {
    GetChannelsList();
    setCurrentChannel(null);
    setCurrentSalesPoint(null);
    setCurrentModalOpen(null);
    setSalesPoints([]);
    setSellerSalesPoints([]);
    setChannelsPricing([]);
  }, []);
  const SalesPointsScheme = Yup.object().shape({
    name: Yup.string().required("No debe quedar vacío"),
    description: Yup.string().optional(),
    salePointId: Yup.string()
      .max(5, "No puede superar los 5 caracteres")
      .optional(),
  });
  const SellerScheme = Yup.object().shape({
    mail: Yup.string().email("Ingrese un email valido").required("No debe quedar vacío"),
    fullName: Yup.string().required("No debe quedar vacío"),
    dni: Yup.number().typeError("Ingrese un DNI valido")
      .min(999999, "El DNI debe tener minimo 7 digitos")
      .required("No debe quedar vacío"),
    salePointId: Yup.string().required("No debe quedar vacío"),
  });
  const channelsPricingScheme = Yup.array()
    .of(
      Yup.object().shape({
        financerPlanId: Yup.string().required("No debe quedar vacío"),
        channelId: Yup.string().required("No debe quedar vacío"),
        price: Yup.number()
          .typeError("Ingrese un formato válido")
          .required("No debe quedar vacío"),
      })
    )
    .min(3, "Debe haber al menos 3 elementos")
    .max(3, "No puede haber más de 3 elementos");
  return (
    <ThemeProvider theme={MuiTheme}>
      <div className="users">
        <div className="users-table-container" style={{ padding: "1rem" }}>
          <Box
            sx={{
              background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
              padding: "10px",
              borderRadius: "15px",
              color: "white",
              height: "80px",
              display: "flex",
              alignItems: "center",
              width: "90%",
              position: "relative",
              bottom: "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 700",
                letterSpacing: " 1px",
              }}
            >
              Gestión de canales
            </Typography>
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              gap: "2rem",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Canal Comercial</InputLabel>
              <Select
                sx={{ width: "15rem" }}
                label="Canal Comercial"
                onChange={({ target }) => {
                  console.log(target.value);
                  setCurrentChannel(target.value);
                  GetSalesPointsList(target.value.id);
                  GetSellerSalesPointsList(target.value.id);
                  GetChannelsPricing(target.value.id);
                  setCurrentSalesPoint(null);
                }}
                value={currentChannel}
              >
                {channels?.map((c) => {
                  return (
                    <MenuItem key={c.id} value={c}>
                      {c.financer}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TableTemplate
              tableHead={
                <>
                  <TableCell>Cdo. Punto Venta</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Acción</TableCell>
                </>
              }
              currentChannel={currentChannel}
              tableRows={salesPoints.map((sp) => (
                <TableRow key={sp.salePointId}>
                  <TableCell>{sp.salePointId}</TableCell>
                  <TableCell>{sp.name}</TableCell>
                  <TableCell>{sp.description}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedSalesPoint(sp);
                        setCurrentModalOpen("SALES_POINTS");
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setCurrentConfirmDialogOpen("SALES_POINTS");
                        setSelectedSalesPoint(sp);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              setOpenFunc={(param) => {
                setCurrentModalOpen("SALES_POINTS");
                setSelectedSalesPoint(null);
              }}
              isButtonDisabled={!currentChannel}
            />
            <Box display={"flex"} alignItems={"center"}>
              <FormControl
                disabled={!salesPoints.length}
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel>Filtro Punto Venta </InputLabel>
                <Select
                  sx={{ width: "15rem" }}
                  label="Filtro Punto Venta"
                  onChange={({ target }) => {
                    setCurrentSalesPoint(target.value);
                    GetSellerBySalesPoints(target.value.salePointId);
                  }}
                  value={currentSalesPoint}
                >
                  {salesPoints?.map((sp) => {
                    return (
                      <MenuItem key={sp.id} value={sp}>
                        {sp.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {currentSalesPoint && (
                <IconButton
                  onClick={() => {
                    setCurrentSalesPoint(null);
                    GetSellerSalesPointsList(currentChannel.id);
                  }}
                >
                  <Clear></Clear>
                </IconButton>
              )}
            </Box>
            <TableTemplate
              tableHead={
                <>
                  <TableCell>Mail</TableCell>
                  <TableCell>Cdo. Punto Venta</TableCell>
                  <TableCell>Apellido y Nombre</TableCell>
                  <TableCell>DNI</TableCell>
                  <TableCell>Link de Venta</TableCell>
                  <TableCell>Accion</TableCell>
                </>
              }
              currentChannel={currentChannel}
              tableRows={sellerSalesPoints?.map((seller) => (
                <TableRow key={seller.id}>
                  <TableCell>{seller.mail}</TableCell>
                  <TableCell>{seller.salePointId}</TableCell>
                  <TableCell>{seller.fullName}</TableCell>
                  <TableCell>{seller.dni}</TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_SALE_URL +
                        `/${seller.sellerToken}`
                      }
                    >
                      Link Vendedora
                    </a>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setSelectedSeller(seller);
                        setCurrentModalOpen("SELLER");
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setCurrentConfirmDialogOpen("SELLER");
                        setSelectedSeller(seller);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              setOpenFunc={(param) => {
                setCurrentModalOpen("SELLER");
                setSelectedSeller(null);
              }}
              isButtonDisabled={!currentChannel || !salesPoints.length}
            />
            {currentChannel?.id != "2a511a20-e69f-47b5-bb6e-32b83792f6ff" && (
              <TableTemplate
                tableHead={
                  <>
                    <TableCell>Producto</TableCell>
                    <TableCell>Importe</TableCell>
                  </>
                }
                currentChannel={currentChannel}
                tableRows={channelsPricing?.map((channelPr) => {
                  let product = "";
                  switch (channelPr.financerPlanId) {
                    case "6bcd6eec-92aa-46ee-afdb-c1ba6866762c":
                      product = "CLASSIC";
                      break;
                    case "a13a636f-2918-4c30-a64b-5a9f4a427e8a":
                      product = "PLUS";
                      break;
                    case "bb0aa40d-febe-4085-86d4-1c091443892f":
                      product = "PREMIUM";
                      break;
                    default:
                      break;
                  }
                  return (
                    <TableRow key={channelPr.id}>
                      <TableCell>{product}</TableCell>
                      <TableCell>{channelPr.price}</TableCell>
                    </TableRow>
                  );
                })}
                setOpenFunc={(param) => {
                  setCurrentModalOpen("CHANNEL_PRICING");
                }}
                isButtonDisabled={!currentChannel}
                deleteButton={true}
                deleteAllFunc={() => {
                  setCurrentConfirmDialogOpen("CHANNEL_PRICING");
                }}
              />
            )}
          </div>
        </div>
      </div>
      <GenericModalForm
        open={currentModalOpen == "SALES_POINTS"}
        setOpen={(e) => setCurrentModalOpen(!e ? null : "SALES_POINTS")}
        title={!selectedSalesPoint ? "Crear nuevo" : "Editar"}
        initialValues={
          selectedSalesPoint || {
            name: "",
            description: "",
            channels: currentChannel,
          }
        }
        validationSchema={SalesPointsScheme}
        onSubmit={(values) => {
          if (selectedSalesPoint) {
            handleSalesPoint("PUT", values, selectedSalesPoint.salePointId);
          } else {
            handleSalesPoint("POST", values);
          }
        }}
        fields={[
          {
            name: "salePointId",
            label: "Cdo. Punto Venta",
          },
          { name: "name", label: "Nombre" },
          { name: "description", label: "Descripción" },
        ]}
      />

      <GenericModalForm
        open={currentModalOpen == "SELLER"}
        setOpen={(e) => setCurrentModalOpen(!e ? null : "SELLER")}
        title={!selectedSeller ? "Crear nuevo" : "Editar"}
        initialValues={
          selectedSeller || {
            mail: "",
            fullName: "",
            dni: "",
            salePointId: currentSalesPoint?.salePointId || "",
          }
        }
        validationSchema={SellerScheme}
        onSubmit={(values) => {
          if (selectedSeller) {
            handleSeller("PUT", values, selectedSeller.id);
          } else {
            handleSeller("POST", values);
          }
        }}
        fields={[
          selectedSeller && {
            name: "id",
            label: "Id",
            disabled: !!selectedSeller,
          },
          { name: "mail", label: "Mail" },
          { name: "fullName", label: "Apellido y Nombre" },
          { name: "dni", label: "DNI" },
          {
            name: "salePointId",
            label: "Punto de Venta",
            type: "select",
            options: salesPoints.map((sp) => ({
              value: sp.salePointId,
              label: sp.name,
            })),
            disabled: !salesPoints.length,
          },
        ].filter(Boolean)}
      />

      {currentChannel && (
        <BaseModal
          open={currentModalOpen == "CHANNEL_PRICING"}
          setOpen={(e) => setCurrentModalOpen(!e ? null : "CHANNEL_PRICING")}
        >
          <h3>Editar precios canal comercial</h3>
          <Formik
            onSubmit={(values) => {
              if (channelsPricing.length) {
                handleChannelsPricing("PUT", values);
              } else {
                handleChannelsPricing("POST", values);
              }
            }}
            initialValues={
              channelsPricing.length
                ? channelsPricing
                : [
                    {
                      channelId: currentChannel.id,
                      financerPlanId: "6bcd6eec-92aa-46ee-afdb-c1ba6866762c",
                      price: null,
                    },
                    {
                      channelId: currentChannel.id,
                      financerPlanId: "a13a636f-2918-4c30-a64b-5a9f4a427e8a",
                      price: null,
                    },
                    {
                      channelId: currentChannel.id,
                      financerPlanId: "bb0aa40d-febe-4085-86d4-1c091443892f",
                      price: null,
                    },
                  ]
            }
            validationSchema={channelsPricingScheme}
          >
            {({
              isValid,
              values,
              setValues,
              setFieldValue,
              errors,
              validateForm,
            }) => {
              return (
                <Form>
                  <Box
                    display="flex"
                    gap="1rem"
                    flexDirection={"column"}
                    mt={"1rem"}
                  >
                    {values.map((v, key) => {
                      let product = "";
                      switch (v.financerPlanId) {
                        case "6bcd6eec-92aa-46ee-afdb-c1ba6866762c":
                          product = "CLASSIC";
                          break;
                        case "a13a636f-2918-4c30-a64b-5a9f4a427e8a":
                          product = "PLUS";
                          break;
                        case "bb0aa40d-febe-4085-86d4-1c091443892f":
                          product = "PREMIUM";
                          break;
                        default:
                          break;
                      }
                      return (
                        <FormikField
                          name="price"
                          label={product}
                          type="number"
                          value={v.price}
                          onChange={({ target }) => {
                            let newValues = values;
                            newValues[key].price = target.value;
                            setValues(newValues);
                          }}
                        />
                      );
                    })}

                    <div style={{ display: "flex" }}>
                      <Button
                        disabled={Object.keys(errors).length != 0}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#002c54",
                          margin: "30px 10px 0 0",
                          borderRadius: "20px",
                          texttransform: "capitalize",
                        }}
                      >
                        Guardar
                      </Button>
                    </div>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </BaseModal>
      )}
      <Dialog
        open={currentConfirmDialogOpen == "SALES_POINTS"}
        onClose={() => {
          setCurrentConfirmDialogOpen(null);
        }}
        style={{ borderRadius: "10px" }}
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar punto de venta?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez borrado el punto de venta no podra recuperarlo, desea
            continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentConfirmDialogOpen(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleSalesPoint("DELETE", null, selectedSalesPoint.salePointId);
            }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={currentConfirmDialogOpen == "SELLER"}
        onClose={() => {
          setCurrentConfirmDialogOpen(null);
        }}
        style={{ borderRadius: "10px" }}
      >
        <DialogTitle id="alert-dialog-title">Eliminar asesor?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez borrado el asesor no podra recuperarlo, desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentConfirmDialogOpen(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleSeller("DELETE", null, selectedSeller.id);
            }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={currentConfirmDialogOpen == "CHANNEL_PRICING"}
        onClose={() => {
          setCurrentConfirmDialogOpen(null);
        }}
        style={{ borderRadius: "10px" }}
      >
        <DialogTitle id="alert-dialog-title">Eliminar precios?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Una vez borrado los precios podra recuperarlos, desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentConfirmDialogOpen(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleChannelsPricing("DELETE");
            }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingModal />}
    </ThemeProvider>
  );
};
const TableTemplate = ({
  tableHead,
  currentChannel,
  tableRows,
  setOpenFunc,
  isButtonDisabled,
  deleteButton = false,
  deleteAllFunc = null,
}) => {
  return (
    <Box display="flex" gap="2rem">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>{tableHead}</TableRow>
          </TableHead>
          <TableBody>
            {currentChannel == null ? (
              <TableRow>
                <TableCell colSpan={6}>
                  Debe seleccionar un canal primero
                </TableCell>
              </TableRow>
            ) : tableRows.length ? (
              tableRows
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No se encontraron registros</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        width={"2rem"}
        height={"2rem"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        overflow={"hidden"}
        bgcolor={isButtonDisabled ? "grey" : "#3992ee"}
        padding={"1.5rem"}
        borderRadius={"100%"}
        color={"white"}
        sx={{
          cursor: isButtonDisabled ? "unset" : "pointer",
          ":hover": {
            bgcolor: isButtonDisabled ? "grey" : "#1565c0",
            transition: ".2s ease-in-out",
          },
        }}
        onClick={() => {
          if (isButtonDisabled) return;
          setOpenFunc(true);
        }}
      >
        <Add />
      </Box>
      {deleteButton && (
        <Box
          width={"2rem"}
          height={"2rem"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          overflow={"hidden"}
          bgcolor={!tableRows.length ? "grey" : "#ff5238"}
          padding={"1.5rem"}
          borderRadius={"100%"}
          color={"white"}
          sx={{
            cursor: isButtonDisabled ? "unset" : "pointer",
            ":hover": {
              bgcolor: isButtonDisabled ? "grey" : "#d32f2f",
              transition: ".2s ease-in-out",
            },
          }}
          onClick={() => {
            if (!tableRows.length) return;
            deleteAllFunc();
          }}
        >
          <DeleteForever />
        </Box>
      )}
    </Box>
  );
};

const GenericModalForm = ({
  open,
  setOpen,
  title,
  initialValues,
  validationSchema,
  onSubmit,
  fields,
}) => {
  return (
    <BaseModal open={open} setOpen={setOpen}>
      <h3>{title}</h3>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, setValues, setFieldValue, errors }) => (
          <Form>
            <Box display="flex" gap="1rem" flexDirection={"column"} mt={"1rem"}>
              {fields.map(({ name, label, type, options, disabled }, index) => {
                if (type === "select") {
                  return (
                    <FormControl
                      key={index}
                      sx={{ minWidth: 120, display: "flex" }}
                      disabled={disabled}
                    >
                      <InputLabel>{label}</InputLabel>
                      <Select
                        sx={{ width: "100%", height: "4rem" }}
                        value={values[name]}
                        onChange={(e) => setFieldValue(name, e.target.value)}
                      >
                        {options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }
                return (
                  <FormikField
                    key={index}
                    name={name}
                    label={label}
                    type={type}
                    disabled={disabled}
                  />
                );
              })}
            </Box>

            <div style={{ display: "flex" }}>
              <Button
                disabled={Object.keys(errors).length !== 0}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#002c54",
                  margin: "30px 10px 0 0",
                  borderRadius: "20px",
                  textTransform: "capitalize",
                }}
              >
                Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
};

export default ChannelsDashboard;
