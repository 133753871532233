import axios from "axios";
const channelsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/channels`;
const channelsPricingUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/channels-pricing`;
const salesPointsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/sales-points`;
const sellerSalesPointsUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/seller-sales-points`;
const validateTokenUrl = `${process.env.REACT_APP_BASE_URL}/services/parametrics/api/link-sale`;
const saleUrl = `${process.env.REACT_APP_BASE_URL}/services/userprofile/api/link-sale`;

export const getChannels = async (token) => {
  return await axios
    .get(channelsUrl, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getSalesPoints = async (token, channelId) => {
  return await axios
    .get(`${salesPointsUrl}/${channelId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postSalesPoints = async (token, data,displayError) => {
  return await axios
    .post(`${salesPointsUrl}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const putSalesPoint = async (token, salesPointId, data,displayError) => {
  return await axios
    .put(`${salesPointsUrl}/${salesPointId}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const deleteSalesPoints = async (token, salesPointId,displayError) => {
  return await axios
    .delete(`${salesPointsUrl}/${salesPointId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};

export const getSellerSalesPoints = async (token, channelId) => {
  return await axios
    .get(`${sellerSalesPointsUrl}/${channelId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getSellerBySalesPoints = async (token, salesPointsId) => {
  return await axios
    .get(`${sellerSalesPointsUrl}/?salesPointId=${salesPointsId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const postSellerSalesPoints = async (token, data, displayError) => {
  return await axios
    .post(`${sellerSalesPointsUrl}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const putSellerSalesPoints = async (token, sellerId, data,displayError) => {
  return await axios
    .put(`${sellerSalesPointsUrl}/${sellerId}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const deleteSellerSalesPoints = async (token, sellerId,displayError) => {
  return await axios
    .delete(`${sellerSalesPointsUrl}/${sellerId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};

export const postSale = async (token,data, displayError) => {
  return await axios
    .post(`${saleUrl}/${token}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const validateToken = async (token, displayError) => {
  return await axios
    .get(`${validateTokenUrl}/validate-token?sellerToken=${token}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};


export const postChannelsPricing = async (token, channelId, data,displayError) => {
  return await axios
    .post(`${channelsPricingUrl}/${channelId}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const putChannelsPricing = async (token, channelId, data,displayError) => {
  return await axios
    .put(`${channelsPricingUrl}/${channelId}`, data, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};
export const getChannelsPricing = async (token, channelId,displayError) => {
  return await axios
    .get(`${channelsPricingUrl}/${channelId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};

export const deleteChannelsPricing = async (token, channelId,displayError) => {
  return await axios
    .delete(`${channelsPricingUrl}/${channelId}`, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error.response?.data);
      displayError(error.response?.data);
    });
};