import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MuiTheme } from "../../styles/MuiDataTableStyle";
import { Check, Clear } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { DatePickerField, FormikField } from "../../components/FormFields";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { postSale, validateToken } from "../../services/channelsService";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import dayjs from "dayjs";
import { LoadingModal } from "../../components/Modals/LoadingModal";

const SaleTestForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [financerPlanId, setFinancerPlanId] = useState(null);
  const [isUrlInvalid, setIsUrlInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const toastDispatch = useToast();
  const displayError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const ValidateSellerToken = async () => {
    setIsLoading(true);
    await validateToken(id, displayError).then((res) => {
      if (!res) {
        setIsUrlInvalid(true);
      }
    });
    setIsLoading(false);
  };

  const PostSale = async (params) => {
    setIsLoading(true);
    await postSale(id, params, displayError).then((res) => {
      if (res) {
        setCurrentStep(3);
      }
    });
    setIsLoading(false);
  };
  useEffect(() => {
    ValidateSellerToken();
  }, []);

  const plans = [
    { id: "6bcd6eec-92aa-46ee-afdb-c1ba6866762c", label: "CLASSIC" },
    { id: "a13a636f-2918-4c30-a64b-5a9f4a427e8a", label: "PLUS" },
    { id: "bb0aa40d-febe-4085-86d4-1c091443892f", label: "PREMIUM" },
  ];
  const services = [
    "Disponibilidad del servicio 24 horas todos los días",
    "Receta electrónica de medicamentos (excepto psicofármacos)",
    "Constancia de atención médicas y certificados laborales",
    "Prescripción de estudios complementarios",
    "Derivación a especialistas",
    "Notificaciones dentro de la plataforma y vía correo electrónico",
    "Historia clínica del paciente",
    "Consultas por videollamadas al mes",
    "Especialidad Clínica Médica",
    "Especialidad Pediatría",
    "Adhesión de miembros del grupo familiar",
  ];

  const features = [
    [true, true, true],
    [true, true, true],
    [true, true, true],
    [true, true, true],
    [true, true, true],
    [true, true, true],
    [true, true, true],
    ["2", "4", "ilimitadas"],
    [true, true, true],
    [false, true, true],
    [false, true, true],
  ];

  const SaleScheme = Yup.object().shape({
    mail: Yup.string().required("No debe quedar vacío"),
    firstName: Yup.string().required("No debe quedar vacío"),
    lastName: Yup.string().required("No debe quedar vacío"),
    phoneNumber: Yup.string().required("No debe quedar vacío"),
    financerPlanId: Yup.string().required("No debe quedar vacío"),
    dni: Yup.number().typeError("El dni solo puede contener numeros")
      .min(999999, "El DNI debe tener minimo 7 digitos")
      .required("No debe quedar vacío"),
    financerPlanId: Yup.string().required("No debe quedar vacío"),
    sex: Yup.string().required("No debe quedar vacío"),
    birthDate: Yup.string().required("No debe quedar vacío"),
  });

  if (isUrlInvalid) {
    return (
      <ThemeProvider theme={MuiTheme}>
        <Box
          display="flex"
          gap="2rem"
          width={"100%"}
          justifyContent={"center"}
          padding={currentStep == 0 ? "3rem" : ""}
        >
          INVALID URL -
          <span style={{ margin: "0", fontWeight: "bold", color: "red" }}>
            Check token
          </span>
        </Box>
      </ThemeProvider>
    );
  }
  if (currentStep == 3) {
    return (
      <ThemeProvider theme={MuiTheme}>
        <Box
          sx={{
            background: "linear-gradient(45deg, #2565a5, #0d2355)",
            padding: "2rem",
            color: "white",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src="https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_268x54.png"
            srcset="https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_268x54.png 1x, https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_536x208.png 2x"
            width="268"
            height="54"
            style={{ maxHeight: "54px", height: "auto" }}
            alt="Mediline Logo"
            data-retina_logo_url="https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_536x208.png"
            class="fusion-standard-logo"
          />
        </Box>
        <Box
          display="flex"
          gap="2rem"
          width={"100%"}
          justifyContent={"center"}
          padding={"3rem"}
        >
          ¡Gracias por sumarte a la familia de MEDILINE!
        </Box>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <Box
        sx={{
          background: "linear-gradient(45deg, #2565a5, #0d2355)",
          padding: "2rem",
          color: "white",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img
          src="https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_268x54.png"
          srcset="https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_268x54.png 1x, https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_536x208.png 2x"
          width="268"
          height="54"
          style={{ maxHeight: "54px", height: "auto" }}
          alt="Mediline Logo"
          data-retina_logo_url="https://mediline.com.ar/wp-content/uploads/2020/09/logo_mediline_536x208.png"
          class="fusion-standard-logo"
        />
      </Box>
      <Box
        display="flex"
        gap="2rem"
        width={"100%"}
        justifyContent={"center"}
        padding={currentStep == 0 ? "3rem" : "1rem"}
      >
        <Formik
          onSubmit={(values) => {
            PostSale(values);
          }}
          initialValues={{
            mail: "",
            dni: "",
            phoneNumber: "",
            financerPlanId: financerPlanId ? financerPlanId : "",
            lastName: "",
            firstName: "",
            sex: "",
            birthDate: "",
          }}
          validationSchema={SaleScheme}
        >
          {({
            isValid,
            values,
            setValues,
            setFieldValue,
            errors,
            validateForm,
          }) => {
            return (
              <>
                {currentStep != 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Button
                      onClick={() => {
                        setCurrentStep(currentStep - 1);
                      }}
                      variant="contained"
                    >
                      Volver
                    </Button>
                  </Box>
                )}
                {currentStep == 0 && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Servicios que brinda la plataforma
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            CLASSIC
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            PLUS
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            PREMIUM
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {services.map((s, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{s}</TableCell>
                              <TableCell>
                                {typeof features[key][0] != "boolean" ? (
                                  features[key][0]
                                ) : features[key][0] ? (
                                  <Check></Check>
                                ) : (
                                  <Clear></Clear>
                                )}
                              </TableCell>
                              <TableCell>
                                {typeof features[key][1] != "boolean" ? (
                                  features[key][1]
                                ) : features[key][1] ? (
                                  <Check></Check>
                                ) : (
                                  <Clear></Clear>
                                )}
                              </TableCell>
                              <TableCell>
                                {typeof features[key][2] != "boolean" ? (
                                  features[key][2]
                                ) : features[key][2] ? (
                                  <Check></Check>
                                ) : (
                                  <Clear></Clear>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell></TableCell>

                          {plans.map((financerPlan) => {
                            return (
                              <TableCell>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setCurrentStep(1);
                                    setFinancerPlanId(financerPlan.id);
                                    setFieldValue(
                                      "financerPlanId",
                                      financerPlan.id
                                    );
                                  }}
                                >
                                  Asociarte
                                </Button>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {currentStep == 1 && (
                  <Box width={"35rem"}>
                    <Form>
                      <Box
                        display={"flex"}
                        flexWrap={"wrap"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"2rem"}
                      >
                        <FormikField
                          style={{ width: "15rem" }}
                          name="firstName"
                          label="Nombre"
                        />
                        <FormikField
                          style={{ width: "15rem" }}
                          name="lastName"
                          label="Apellido"
                        />
                        <FormikField
                          style={{ width: "15rem" }}
                          name="mail"
                          label="Mail"
                        />
                        <FormikField
                          style={{ width: "15rem" }}
                          name="dni"
                          label="DNI"
                        />
                        <FormikField
                          style={{ width: "15rem" }}
                          name="phoneNumber"
                          label="Celular"
                        />
                        <FormControl sx={{width:"15rem"}}>
                          <DatePickerField
                            label="Fecha Inicio"
                            value={"28/06/2003"}
                            defaultValue={dayjs(new Date("28/06/2003"))}
                            slotProps={{
                              textField: {
                                variant: "standard",
                                disabled: true,
                              },
                            }}
                            onChange={(value) => {
                              setFieldValue(
                                "birthDate",
                                value.format("YYYY-MM-DDT00:00:00Z")
                              );
                            }}
                          />
                        </FormControl>
                        <FormControl
                          sx={{ minWidth: "15rem", display: "flex" }}
                        >
                          <InputLabel>Punto de Venta</InputLabel>
                          <Select
                            sx={{ width: "100%", height: "4rem" }}
                            label="Punto de Venta"
                            onChange={({ target }) => {
                              setFieldValue("financerPlanId", target.value);
                            }}
                            value={values.financerPlanId}
                          >
                            {plans?.map((sp) => {
                              return (
                                <MenuItem key={sp.id} value={sp.id}>
                                  {sp.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl
                          sx={{ minWidth: "15rem", display: "flex" }}
                        >
                          <InputLabel>Genero</InputLabel>
                          <Select
                            sx={{ width: "100%", height: "4rem" }}
                            label="Genero"
                            onChange={({ target }) => {
                              setFieldValue("sex", target.value);
                            }}
                            value={values.sex}
                          >
                            {["MALE","FEMALE","NON_BINARY"]?.map((sp,key) => {
                              return (
                                <MenuItem key={sp} value={sp}>
                                  {sp}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "90%",
                          }}
                        >
                          <Button
                            disabled={Object.keys(errors).length != 0}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#002c54",
                              borderRadius: "20px",
                              texttransform: "capitalize",
                            }}
                          >
                            Guardar
                          </Button>
                        </div>
                      </Box>
                    </Form>
                  </Box>
                )}
              </>
            );
          }}
        </Formik>
      </Box>
      {isLoading && <LoadingModal />}
    </ThemeProvider>
  );
};

export default SaleTestForm;
